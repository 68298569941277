
import { defineComponent } from 'vue';
import Panel from '@/components/layout/Panel.vue';
import ViewMixin from '@/mixins/ViewMixin';
import TextField from '@/components/controls/TextField.vue';
import Button from '@/components/controls/Button';
import Dialog from '@/components/dialogs/Dialog.vue';
import EditItem from '@/components/ingame/item/EditItem.vue';
import * as itemService from '@/services/ingame/itemService';
import { Item } from '@/interfaces/ingame/item';
import LoadingIndicatorBeam from '@/components/loading/LoadingIndicatorBeam.vue';
import DataItem from '@/components/layout/DataItem.vue';
import { ROLE_MODERATOR } from '@/constants/roles';
import { uniqueId, numberToLocaleString } from '@/helpers';

interface Data {
    searchTerm: string;
    showEditDialog: boolean;
    editId: string | null;
    isLoading: boolean;
    items: Item[];
    textToCopy: string;
}

export default defineComponent({
    name: 'Items',
    components: {
        Dialog,
        Button,
        Panel,
        TextField,
        EditItem,
        LoadingIndicatorBeam,
        DataItem,
    },
    mixins: [ViewMixin],
    data: (): Data => ({
        searchTerm: '',
        showEditDialog: false,
        editId: null,
        isLoading: false,
        items: [],
        textToCopy: '',
    }),
    computed: {
        clipboardInputId(): string {
            return `clipboardInput_${uniqueId}}`;
        },
        isModerator(): boolean {
            return this.$store.getters['authentication/hasOneRoles']([ROLE_MODERATOR]);
        },
        textProperties(): string[] {
            return ['adjacencyHeatValues'];
        },
        excludedProperties(): string[] {
            return ['id', 'iconUri', 'createdAt', 'updatedAt', 'description', 'itemCategoryId', 'primaryMaterialId', 'name', 'wikiUri'];
        },
    },
    methods: {
        numberToLocaleString,
        copyToClipboard(text: string): void {
            this.textToCopy = text;
            const input = document.getElementById(this.clipboardInputId) as HTMLInputElement;

            this.$nextTick(() => {
                /* Select the text field */
                input.select();
                /* For mobile devices */
                input.setSelectionRange(0, 99999);

                document.execCommand('copy');

                this.$notify({
                    title: this.$t('copied'),
                    text: this.$t('idCopied'),
                    type: 'success',
                });
            });
        },
        showCreate(): void {
            this.editId = null;
            this.showEditDialog = true;
        },
        showEdit(id: string) {
            this.editId = id;
            this.showEditDialog = true;
        },
        hideEditDialog(refreshData = false): void {
            this.showEditDialog = false;
            this.editId = null;
            if (refreshData) {
                this.loadItems();
            }
        },
        async refreshData(): Promise<void> {
            await this.loadItems();
        },
        async loadItems(): Promise<void> {
            this.isLoading = true;
            try {
                const response = await itemService.getMultiple({
                    pageSize: -1,
                    searchQuery: this.searchTerm,
                });
                this.items = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
    },
    created(): void {
        this.setPageTitle([this.$t('items'), this.$t('ingame')]);
        this.refreshData();
    },
});
